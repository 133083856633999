import { RouterProvider } from "react-router-dom";
import { routes } from "./router/route";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for Toastify
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./redux/store";
import { fetchUserInfo, user } from "./redux/reducers/UserReducer";

function App() {
  const dispatch = useDispatch<AppDispatch>();
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(user);

  useEffect(() => {
    const token = localStorage.getItem("token");
    // if(token)
    if(token && userData?.status === 401){
      localStorage.removeItem("token")
      localStorage.removeItem("userroles")
      toast.dismiss()
      toast.error(userData.message);
      return;
    }
    if (token && userData?.status != 401 && userData?.status != 200) {
      axios.defaults.headers["authentication"] = token;
      dispatch(fetchUserInfo());
    }
  }, [userData]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <RouterProvider router={routes} />
    </>
  );
}

export default App;
